<template>
  <div>
    <!-- <h4>ARANCEL DE PRECIOS</h4> -->
    <router-link class="btn btn-primary mb-2" to="/dental-clinic/products">
      <i class="fas fa-arrow-left"></i> Retornar
    </router-link>
    <h4>{{ $route.query.categorie }}</h4>
    <ProductStateIndex :idCategory="categorie_id"></ProductStateIndex>
  </div>
</template>
<script>
// import { GeneralService } from "../../general-module/GeneralService";
// import indexProducts from "src/lt/store-module/products/Index.vue";
import ProductStateIndex from "../odontogram/product-states/Index.vue";
import { DentalClinicService } from "../service";
import { mapState } from "vuex";

export default {
  components: {
    ProductStateIndex
    // indexProducts
  },

  // directives
  // filters

  props: {
    categorie_id: {}
  },

  data: () => ({
    list: {}
  }),

  computed: {
    ...mapState({
      area_id: (s) => s.config.dental.area_id
    })
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    getList(params) {
      return new Promise(() => {
        DentalClinicService.getProductcategories({
          ...params
        }).then((list) => {
          this.list = list;
        });
        // GeneralService.getCategories(params).then(res => {
        //   this.list = res;
        //   rsv(res);
        // });
      });
    }
  }
};
</script>

<style></style>
